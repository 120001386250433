import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { FlexBox } from '../common/wrappers';
import { ColoredCircle } from '../common/elements';
import { OrderItem } from '../../interface/orders-interface';
import PreviewModal from '../order-details/details/modals/preview-modal';
import ImageBox from '../common/image-box';
import { CatalogProductCategory } from '../catalog';
import CustomToolTip from '../custom-tooltip';
import Typography from '../typography';

interface Props {
  className?: string;
  product?: OrderItem;
  forHover?: boolean;
  forModal?: boolean;
  imageUrls?: any;
}
const MiniImageInfoSection: React.FC<Props> = ({
  className,
  product,
  forHover = false,
  forModal = false,
  imageUrls,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const printAreas = useMemo(() => {
    const { frontDesign, backDesign, category, printAreasForCatalog } =
      product || {};
    if (category === CatalogProductCategory.APPAREL) {
      if (frontDesign && backDesign) return 'Front Side, Back Side';
      if (frontDesign) return 'Front Side';
      if (backDesign) return 'Back Side';
      return 'No Side';
    }
    return printAreasForCatalog?.join(', ') || '';
  }, [product]);

  const handleImageClick = () => {
    if (forModal) setShowPreviewModal(true);
  };

  const mockupUrls = useMemo(() => {
    const { personalization, frontMockupURL, backMockupURL } = product || {};
    const {
      frontMockupURL: personalizedFront,
      backMockupURL: personalizedBack,
    } = personalization || {};

    return [
      personalizedFront || frontMockupURL,
      personalizedBack || backMockupURL,
    ].filter(Boolean) as string[];
  }, [product]);

  const getVariantProperty = useCallback(
    (propertyName: 'color' | 'size') =>
      product?.variantProperties?.find(
        (property) => property.propertyName === propertyName,
      ),
    [product?.variantProperties],
  );

  const colorInfo = useMemo(() => {
    const colorProperty = getVariantProperty('color');
    const colorHex = colorProperty?.meta?.colorHex || '000000';
    const colorName = colorProperty?.propertyValue || 'white';
    return {
      colorCode: colorHex.includes('/') ? colorHex : `#${colorHex}`,
      colorName,
    };

  }, [getVariantProperty]);

  return (
    <StyledWrapper className={className} forHover={forHover}>
      {forHover ? (
        <>
          <FlexBox
            direction="column"
            gap="1rem"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '2px' }}
          >
            <FlexBox gap="10px">
              {imageUrls?.slice(0, 2)?.map((item: any, index: number) => (
                <ImageBox
                  isClickable
                  className="item-header"
                  key={index}
                  width="92px"
                  boxHeight="92px"
                  image={item}
                ></ImageBox>
              ))}
            </FlexBox>
            {imageUrls && imageUrls.length > 2 && (
              <>
                <FlexBox
                  className="grey700"
                  style={{
                    fontSize: '16px',
                  }}
                >
                  <span>+ {`${imageUrls.length - 2}`} more Items</span>
                </FlexBox>
              </>
            )}
          </FlexBox>
        </>
      ) : (
        <>
          <div className="image-wrapper" onClick={handleImageClick}>
            <img
              src={`${mockupUrls[0]}?${Date.now()}`}
              alt="product_image"
              className={
                product?.category === CatalogProductCategory.DRINKWARE
                  ? 'set-height'
                  : ''
              }
            />

            <div className="quantity">{mockupUrls.length}</div>
          </div>
          <FlexBox
            direction="column"
            gap="12px"
            className="product-info-wrapper"
          >
            <FlexBox gap="8px" className="product-details" alignItems="center">
              <CustomToolTip
                key={colorInfo.colorCode}
                title={
                  <Typography
                    text={
                      colorInfo.colorName.includes('/')
                        ? `${colorInfo.colorName} (#${
                            colorInfo.colorCode.split('/')[1]
                          })`
                        : `${colorInfo.colorName} (${colorInfo.colorCode})`
                    }
                    fontSize="0.8rem"
                    classname="box p-10px"
                  />
                }
              >
                <ColoredCircle
                  colorCode={colorInfo.colorCode}
                  width={18}
                  height={18}
                />
              </CustomToolTip>

              <span className="small-dot"></span>
              <div className="product-size">
                {getVariantProperty('size')?.propertyValue ?? '--'}
              </div>
              <span className="small-dot"></span>
              <div>
                {product?.quantity ?? '--'}{' '}
                {product?.quantity && product?.quantity > 1 ? 'items' : 'item'}
              </div>
            </FlexBox>
            <>
              <FlexBox justifyContent="space-between">
                <span>Brand</span>
                <span>{product?.brand}</span>
              </FlexBox>
              {product?.model && (
                <FlexBox justifyContent="space-between">
                  <span>Model Number</span>
                  <span>{product.model}</span>
                </FlexBox>
              )}
              <FlexBox justifyContent="space-between">
                <span>Produced by</span>
                <span>{product?.producedBy}</span>
              </FlexBox>
              <FlexBox justifyContent="space-between">
                <span>Print Locations</span>
                <span>{printAreas}</span>
              </FlexBox>
            </>
          </FlexBox>
        </>
      )}

      {showPreviewModal && product && (
        <PreviewModal
          onClose={() => setShowPreviewModal(false)}
          ImageArray={mockupUrls}
        />
      )}
    </StyledWrapper>
  );
};

export default MiniImageInfoSection;
export const StyledWrapper = styled.div<{
  forHover: boolean;
}>`
  box-sizing: border-box;
  width: ${(props) => (props.forHover ? 'auto' : '384px')};
  height: ${(props) => (props.forHover ? 'fit-content' : '116px')};
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey['border']};
  padding: 12px;
  .image-wrapper {
    height: 92px !important;
    width: 92px !important;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid ${colors.grey[100]};
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
      border-radius: 6px;
      &.set-height {
        width: inherit;
        object-fit: contain;
      }
    }
    .quantity {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      position: absolute;
      background-color: ${colors.black};
      color: ${colors.white};
      bottom: 0;
      right: 0px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .product-info-wrapper {
    width: 100%;
    .product-title {
      font-weight: 700;
      font-size: 14px;
      color: ${colors.grey[900]};
    }
    .product-details {
      font-size: 14px;
      color: ${colors.grey['700']};
    }
  }
  .preview-modal {
    height: fit-content;
    .image-box-container {
      max-height: 70vh;
    }
  }
  .activeBox {
    border: 2px solid ${colors.blue['800']};
  }
  .imagebox {
    margin-bottom: 15px;
  }
  .bottom-button-wrapper {
    transform: translateX(-50%);
    margin-top: 20px;
    user-select: none;
  }
`;
