import { Option } from '.';

export interface SelectedProduct {
  selectedProductId: string;
  productColorID: number;
  selectedColor: string;
  selectedSize: string;
  quantity: number;
  skuId: string;
  brand: string;
  modelNumber: string;
  productCost: number;
  mockupImage: string;
  designImage: string;
  colorsOptions: Option[];
  sizesOptions: Option[];
  title: string;
  printProviderId: string;
  variants: any;
}
export interface Recipient {
  fullName: string;
  email: string;
  phone: string;
}
export interface Address {
  country: string;
  state: string;
  address: string;
  firstLine: string;
  city: string;
  zipCode: string;
}

interface Shipping {
  sendShippingNotification: boolean;
  shippingType: string;
}
interface Billing {
  couponCode: string;
  totalAmount: number;
}

export interface CreateOrderInitialState {
  selectedProductDetails: SelectedProduct;
  recipient: Recipient;
  shippingAddress: Address;
  billingAddress: Address;
  shipping: Shipping;
  billing: Billing;
  sameAsShippingAddress: boolean;
  createOrderModal: CreateorderModals;
}

export enum CreateorderModals {
  RecipientModal = 'Recipient',
  DiscardOrderModal = 'Discard order',
  ShippingAddressModal = 'Shipping address',
  BillingAddressModal = 'Billing address',
  PayForYourOrderModal = 'Pay for your order',
  None = 'none',
}

export interface AddressPayloadInterface extends Address {
  sectionName: 'shippingAddress' | 'billingAddress';
}

export interface Customer {
  email: string;
  fullName: string;
  phone: string;
  designFileUrl?: string;
}

export interface CreateOrderAPIPayload {
  payload: {
    productId: string;
    catalogSKUId: number;
    quantity: number;
    customer: Customer;
    shippingAddress: Address;
    billingAddress: Address;
    placeOrder: boolean;
    productColorID: number;
  };
  storeId: string;
}

export interface SampleOrderCart {
  productId: string;
  catalogId: string;
  productColorID: number;
  printSKUId: string;
  catalogSKUId: number;
  quantity: number;
  cost: number;
  color: string;
  size: string;
  variantId: string;
  image: string;
  title: string;
  printProviderId: string;
  colorName: string;
  isPersonalizable: boolean;
}
