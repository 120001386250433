import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../assets/colors';
import { ColorPicker } from './color-picker';
import MyMockups from './my-mockups';
import Loader from '../../common/loader';
import Button from '../../common/button';
import Checkbox from '../../common/checkbox';
import ImageBox from '../../common/image-box';
import setupPixi, { replaceUrl } from '../mockup-generator';
import { useGetMockupsQueryFromMockupServer } from '../../../store/services/mockups';
import * as PIXI from 'pixi.js';
import {
  setMockups,
  updateMockups,
  setSelectedImages,
  deleteImageOnDeselect,
  setClickedImage,
  incrementCurrentPage,
  storeMockupApiResponse,
  getMockups,
  resetCurrentPage,
  replaceSelectdImage,
} from '../../../store/slices/mockupSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  MockupApiData,
  MockupData,
  MockupVariant,
} from '../../../interface/mockups-interface';
import { Option } from '../../../interface';
import { getUserData } from '../../../store/slices/authSlice';
import {
  getUtilityData,
  setIsDataSaved,
} from '../../../store/slices/utilitySlice';
import { ReactComponent as EditMockupIcon } from '../../../assets/images/edit-3.svg';
import { Tabs } from '../../editor/subComponents/right-sidebar/add-design-tabs';
import SmallLoader from '../../common/small-loader';
import { useGetPerPage } from '../../../hooks/useGetPerPage';
import Typography from '../../typography';
import { ReactComponent as CloseIcon } from '../../../assets/images/cross.svg';
import { FlexBox } from '../../common/wrappers';
import MugPreviewModal, {
  SnapImage,
} from '../../editor/subComponents/mugs/MugPreviewModal';
import { PlusIcon } from '../../../assets/icons';
import { loadImagesWithWorker } from '../workers/preload-images';
import {
  addToQueueToGenerateHighQualityImages,
  generateHighResolutionMockupForSelectedImage,
  ImageQueueItem,
  imageWorkerCallBack,
  processQueue,
} from '../utils';
import { openLinkInNewTab } from '../../../helper/openLinkInNewTab';
import {
  MugType,
  ProductName,
  ProductType,
} from '../../../interface/product-interface';
import { routeNames } from '../../../constants/routesPath';

interface Props {
  variants: MockupVariant[];
  setIsHighResolutionMockupLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isHighResolutionMockupLoading: boolean;
  isTablet: boolean;
  onClose?: () => void;
  productType: string;
  productName: string | undefined;
  printSides: string[];
  model: string;
  setImageQueue: any;
  imageQueue: ImageQueueItem[];
  uploadState: any;
  setUploadState: any;
}
const RightSideBar: React.FC<Props> = ({
  variants,
  setIsHighResolutionMockupLoading,
  isHighResolutionMockupLoading,
  isTablet,
  onClose,
  productType,
  productName,
  printSides,
  model,
  imageQueue,
  setImageQueue,
  uploadState,
  setUploadState,
}) => {
  // const [imageQueue, setImageQueue] = useState<ImageQueueItem[]>([]);
  const processingQueueRef = useRef(false);
  const [preloadedImages, setPreloadedImages] = useState<{
    [key: string]: string;
  }>({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(getUserData);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [designFile, setDesignFile] = useState<string>('');
  const { modelNumber, productId } = useParams();
  const [currentTab, setCurrentTab] = useState(mockupTabs[0].value);
  const [pixiApp, setPixiApp] = useState<PIXI.Application<PIXI.ICanvas>>();
  const [designFilesBlobURLFromState, setDesignFilesBlobURLFromState] =
    useState<null | { [key: string]: string }>(null);
  const [pixiAppForHighResolutionMockup, setPixiAppForHighResolutionMockup] =
    useState<PIXI.Application<PIXI.ICanvas>>();

  const location = useLocation();
  const [showSnapModal, setShowSnapModal] = useState(false);
  const [snapImages, setSnapImages] = useState<SnapImage[]>([]);
  const [sideWithDeisgn, setSideWithDesign] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [mockupAngle, setMockupAngle] = useState<string>();

  const {
    currentPage,
    mockupData: mockups,
    mokupApiResponse,
    selectedImages,
  } = useAppSelector(getMockups);

  const { extraMockupCount, dataChanged } = useAppSelector(getUtilityData);

  const perPage = useGetPerPage();

  const [selectedVariant, setSelectedVariant] = useState<MockupVariant>();

  const isDefaultMockups = ['3001', '3501', 'C1717'].includes(
    modelNumber as string,
  );
  const isColoredMug = productName === ProductName.ColoredMug ? true : false;
  const isBlackMug = productName === ProductName.BlackMug;

  const {
    data: rawData,
    isLoading: isRawAPILoading,
    isFetching,
  } = useGetMockupsQueryFromMockupServer(
    {
      modelNumber:
        (modelNumber === '3001' || modelNumber === '3501') &&
        selectedVariant?.isHeather
          ? modelNumber + 'C' // for heather colors
          : modelNumber,
      pageNo: currentPage,
      perPage,
      mockupAngle,
      selectedColor: selectedVariant?.colorHex,
      textureType: selectedVariant?.isHeather ? 'heathered' : 'normal',
      isDefault: !isDefaultMockups,
    },
    {
      skip:
        !selectedVariant ||
        !modelNumber ||
        !mockupAngle ||
        productType === ProductType.Mug,
    },
  );

  useEffect(() => {
    const imagesData = location.state?.imagesData ?? null;

    const fetchData = async () => {
      const variant = variants[0];

      const designFileBase64: any = {};

      const designFiles = [];

      const sideWithDesign: string[] = [];

      for (const side of printSides) {
        const designKey = `${side}Design`;

        if (imagesData) {
          for (const key in imagesData) {
            if (Object.prototype.hasOwnProperty.call(imagesData, key)) {
              const element: Blob = imagesData[key];
              const designBlobUrl =
                element instanceof Blob ? URL.createObjectURL(element) : null;

              designFileBase64[key] = designBlobUrl;
            }
          }
          const finaldesignBlobUrl =
            designFileBase64[`${variant.id}-${designKey}`] ??
            designFileBase64[designKey];

          if (finaldesignBlobUrl) {
            sideWithDesign.push(side);
            designFiles.push(finaldesignBlobUrl);
          }
        } else {
          if ((variant as any)[`${side}Design`]) {
            const designFileLinkFromVariant = (variant as any)[designKey];
            const finalDesign = designFileBase64[`${variant.id}-${side}Design`];
            if (designFileLinkFromVariant) {
              designFiles.push(designFileLinkFromVariant ?? finalDesign);
              sideWithDesign.push(side);
            }
          }
        }
      }
      setSelectedVariant(variants[0]);
      setDesignFilesBlobURLFromState(designFileBase64);
      setDesignFile(designFiles[0]);
      setMockupAngle(sideWithDesign[0]);
      setSideWithDesign(sideWithDesign);
    };

    if (printSides.length > 0 && variants.length > 0) {
      fetchData();
    }
  }, [location.state, printSides, variants]);

  async function createMockups(app: PIXI.Application<PIXI.ICanvas>) {
    const genratedMockupImages: MockupData[] = [];
    setLoading(true);
    try {
      let imagesObj = preloadedImages || {};

      if (!rawData || !rawData.results || !selectedVariant) return;

      const allImgs: string[] = [];

      rawData.results.forEach((obj) => {
        allImgs.push(replaceUrl(obj.main_image_url, 400, 400));
        allImgs.push(replaceUrl(obj.full_alpha_image_url, 400, 400));
      });

      const unmatchedUrls = allImgs.filter(
        (url) => !preloadedImages.hasOwnProperty(url),
      );

      if (
        Object.keys(preloadedImages).length === 0 ||
        unmatchedUrls.length > 0
      ) {
        const preloadedImage = await loadImagesWithWorker(
          allImgs,
          workerCallBack,
        );

        imagesObj = Object.keys(preloadedImage).reduce((acc, key) => {
          const blob = preloadedImage[key];
          if (blob instanceof Blob) {
            acc[key] = URL.createObjectURL(blob);
          } else {
            console.error(`Expected Blob, but received ${typeof blob}`);
          }
          return acc;
        }, {} as { [key: string]: string });
      }

      for (const item of rawData.results) {
        const mainImg = imagesObj[replaceUrl(item.main_image_url, 400, 400)];
        const alphaImg =
          imagesObj[replaceUrl(item.full_alpha_image_url, 400, 400)];

        const generatedMockup = await setupPixi(
          mainImg || item.main_image_url,
          alphaImg || item.full_alpha_image_url,
          designFile,
          item.boundary_width,
          item.boundary_height,
          item.boundary_x,
          item.boundary_y,
          item.id,
          item.curves_difficulty,
          selectedVariant.colorHex,
          app,
          item.org_width,
          item.org_height,
          400,
          400,
          mockupAngle + selectedVariant.colorHex,
        );

        const isSelected = selectedImages.some((selectedItem: any) => {
          return (
            selectedItem.mockupId ===
            mockupAngle + selectedVariant.colorHex + '-' + item.id
          );
        });
        const mockupObj = {
          id: mockupAngle + selectedVariant.colorHex + '-' + item.id,
          image: generatedMockup.src,
          selected: isSelected,
        };
        genratedMockupImages.push(mockupObj);
      }

      if (currentPage === 1) {
        dispatch(setMockups(genratedMockupImages));
      } else {
        dispatch(updateMockups(genratedMockupImages));
      }
    } catch (err) {
      console.error('ERROR from Mockup Promises - ', err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setIsDataSaved(false));
    };
  }, [dispatch, location.pathname]);
  const handleSelect = (id: number | string, value?: boolean) => {
    const updatedMockups = mockups.map((mockup: MockupData) => {
      const isMymockup = mockup.image.includes('https://') ? true : false;

      if (mockup.id === id) {
        if (mockup.selected === false) {
          if (!isMymockup && mokupApiResponse.length > 0) {
            const foundMockup = mokupApiResponse.find((mockupRes) => {
              return (
                `${mockupAngle}${selectedVariant?.colorHex}-${mockupRes.id}` ===
                mockup.id
              );
            });
            if (foundMockup) {
              // dispatch low-quality image immediately
              dispatch(
                setSelectedImages({
                  mockupId: mockup.id,
                  mockupImage: mockup.image,
                  isMymockup,
                }),
              );
              dispatch(
                setClickedImage({
                  imgId: mockup.id,
                  imageUrl: mockup.image,
                }),
              );

              // addding to queue for high-quality image generation
              addToQueueToGenerateHighQualityImages(
                mockup.id,
                foundMockup,
                `#${selectedVariant?.colorHex}`,
                designFile,
                mockupAngle as string,
                setImageQueue,
              );
            }
          } else {
            dispatch(
              setSelectedImages({
                mockupId: mockup.id,
                mockupImage: mockup.image,
                isMymockup,
              }),
            );
            dispatch(
              setClickedImage({
                imgId: mockup.id,
                imageUrl: mockup.image,
              }),
            );
          }
        } else if (mockup.selected === true) {
          dispatch(
            deleteImageOnDeselect({
              mockupId: mockup.id,
            }),
          );
        }

        return {
          ...mockup,
          selected: value || !mockup.selected,
        };
      }
      return mockup;
    });
    dispatch(setMockups(updatedMockups));
  };
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const { scrollHeight, scrollTop, clientHeight } =
      event.target as HTMLElement;

    if (
      scrollHeight - scrollTop <= clientHeight + 1 &&
      scrollTop !== 0 &&
      !isFetching &&
      !loading
    ) {
      if (currentPage < totalPages) {
        dispatch(incrementCurrentPage());
      }
    }
  };

  const handleColorChange = (variant: any) => {
    // if same variant is selected then return
    if (selectedVariant?.id === variant.id) return;

    // reset the mockups and page other than mugs

    if (productType !== ProductType.Mug) {
      dispatch(setMockups([]));
      dispatch(resetCurrentPage());
      setLoading(true);
    }

    const variantToUpdate = variants.find((e) => e.id === variant.id);

    if (variantToUpdate) {
      if (
        designFilesBlobURLFromState !== null &&
        Object.keys(designFilesBlobURLFromState).length
      ) {
        setDesignFile(
          designFilesBlobURLFromState[
            `${variantToUpdate.id}-${mockupAngle}Design`
          ] ?? designFilesBlobURLFromState[mockupAngle + 'Design'],
        );
      } else {
        setDesignFile((variantToUpdate as any)[`${mockupAngle}Design`]);
      }
      setSelectedVariant(variantToUpdate);
    }
  };

  useEffect(() => {
    if (rawData) {
      if (!pixiApp) {
        const app = new PIXI.Application({
          width: 200,
          height: 250,
          backgroundColor: 0xffffff,
        });

        setPixiApp(app);
        if (
          perPage &&
          currentPage * perPage + extraMockupCount !== mockups.length
        ) {
          createMockups(app);
        }
      } else {
        createMockups(pixiApp);
      }
      if (!pixiAppForHighResolutionMockup) {
        const highResolutionApp = new PIXI.Application({
          width: 200,
          height: 250,
          backgroundColor: 0xffffff,
        });
        setPixiAppForHighResolutionMockup(highResolutionApp);
      }
      dispatch(storeMockupApiResponse(rawData.results));
      setTotalPages(rawData.total_pages);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  /** callback func for web worker */
  const workerCallBack = (data: { [key: string]: Blob }, err: any) => {
    imageWorkerCallBack(data, err, setPreloadedImages);
  };

  useMemo(async () => {
    if (mokupApiResponse.length > 0) {
      const imagesToPreload: string[] = [];
      const smallImages: string[] = [];
      mokupApiResponse.forEach((x) => {
        smallImages.push(
          replaceUrl(x.main_image_url, 400, 400),
          replaceUrl(x.full_alpha_image_url, 400, 400),
        );
        imagesToPreload.push(
          replaceUrl(x.main_image_url, 'full', 'full'),
          replaceUrl(x.full_alpha_image_url, 'full', 'full'),
        );
      });
      await loadImagesWithWorker(smallImages, workerCallBack);

      loadImagesWithWorker(imagesToPreload, workerCallBack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mokupApiResponse.length]);

  useEffect(() => {
    if (
      currentPage !== 1 &&
      (loading || isRawAPILoading) &&
      scrollRef.current
    ) {
      scrollRef.current.scrollTop = scrollRef.current.scrollTop + 90;
    }
  }, [loading, isRawAPILoading, currentPage]);

  useEffect(() => {
    /** we only receive catalogProductId & printProviderId for newly create product */
    const catalogProductId = location.state?.catalogProductId || null;
    const printProviderId = location.state?.printProviderId || null;

    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();
      if (catalogProductId && printProviderId) {
        navigate(
          `${routeNames.editor}/${catalogProductId}/${printProviderId}/${productId}`,
        );
      }
    };

    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [location.state, navigate, productId]);

  const handleAngleChange = (side: string) => {
    if (side === mockupAngle || loading) return;

    /** reseting */
    dispatch(setMockups([]));
    setMockupAngle(side);
    dispatch(resetCurrentPage());

    if (selectedVariant) {
      setLoading(true);
      if (
        designFilesBlobURLFromState !== null &&
        Object.keys(designFilesBlobURLFromState).length
      ) {
        setDesignFile(
          designFilesBlobURLFromState[`${selectedVariant.id}-${side}Design`] ??
            designFilesBlobURLFromState[side + 'Design'],
        );
      } else {
        setDesignFile((selectedVariant as any)[`${side}Design`]);
      }
    }
  };

  useEffect(() => {
    if (imageQueue.length > 0 && !processingQueueRef.current) {
      processQueue(
        processingQueueRef,
        imageQueue,
        setImageQueue,
        generateAndDispatchHighQualityImage,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageQueue]);

  const generateAndDispatchHighQualityImage = async (
    id: string | number,
    mockupAPIData: MockupApiData,
    selectedColorHex: string,
    designImage: string,
    sideOfMockup: string,
  ) => {
    if (mockupAPIData) {
      const highQualityImage =
        await generateHighResolutionMockupForSelectedImage(
          mockupAPIData,
          preloadedImages,
          pixiAppForHighResolutionMockup,
          selectedColorHex,
          setIsHighResolutionMockupLoading,
          designImage,
          sideOfMockup,
        );
      dispatch(
        replaceSelectdImage({
          mockupId: id,
          mockupImage: highQualityImage,
          isMymockup: false,
        }),
      );
    }
  };

  return (
    <>
      {isRawAPILoading || uploadState.triggered ? <Loader /> : null}
      <Section>
        <FlexBox alignItems="center" justifyContent="space-between">
          <Heading>Product Images</Heading>
          {onClose && (
            <CloseIcon className="pointer-cursor" onClick={onClose} />
          )}
        </FlexBox>
        <Filters>
          {/* <SearchBar
            value={searchValue}
            icon={SearchIcon}
            placeholder="Search"
            onChange={handleSearch}
          /> */}
        </Filters>
        <Tabs>
          {([ProductType.ApparelLight, ProductType.ApparelHeavy].includes(
            productType as any,
          )
            ? mockupTabs
            : mockupTabs.slice(0, 2)
          ).map((tabItem) => {
            return (
              <span
                key={tabItem.value}
                className={`${currentTab === tabItem.value ? 'active' : ''}`}
                onClick={() => setCurrentTab(tabItem.value)}
              >
                {tabItem.label}
              </span>
            );
          })}
        </Tabs>

        {currentTab === 'myMockups' ? (
          <MyMockups onClose={onClose} />
        ) : currentTab === 'mockupGenerator' ? (
          <>
            <FlexBox direction="column" gap="0.5rem" className="colors-wrapper">
              <span className="fs-16" color="grey900">
                Colors
              </span>
              <ColorPicker
                disabled={loading}
                selectedVariantId={selectedVariant?.id}
                onClick={(id) => {
                  !loading && handleColorChange(id);
                }}
                options={variants.map((variant) => ({
                  label: variant.label,
                  id: variant.id,
                  value: variant.colorHex.includes('/')
                    ? variant.colorHex
                    : `#${variant.colorHex}`,
                }))}
              />
            </FlexBox>
            {productType === ProductType.Mug && (
              <>
                {mockups.filter(
                  (mockup) =>
                    mockup.id.toString().split('-').slice(2).join('-') ===
                    selectedVariant?.label.replace(/\//g, '-'),
                ).length === 0 ? (
                  <FlexBox
                    direction="column"
                    gap="0.5rem"
                    className="colors-wrapper"
                  >
                    <Typography
                      text="You don't have any mockups for this product yet"
                      fontSize="16px"
                      fontWeight="400"
                    />
                    <Typography
                      text={`You can create mockups by choosing a color and angle and
                   then clicking the "Take a Snap" button`}
                      fontSize="16px"
                      fontWeight="400"
                    />
                    <Button
                      icon={PlusIcon}
                      appearance={'secondary'}
                      onClick={() => setShowSnapModal(true)}
                      label={'Create More'}
                      size="medium"
                      className="create-mockup-btn"
                    />
                  </FlexBox>
                ) : (
                  <Button
                    icon={PlusIcon}
                    appearance={'secondary'}
                    onClick={() => setShowSnapModal(true)}
                    label={'Create more mockups'}
                    size="fullWidth"
                    className="create-more-btn"
                  />
                )}
              </>
            )}
            {/* Mockup sides */}
            {[
              ProductType.ApparelHeavy,
              ProductType.ApparelLight,
              ProductType.Ornament,
            ].includes(productType as any) && (
              <Tags>
                {sideWithDeisgn.map((side: any) => {
                  return (
                    <Tag
                      key={side}
                      isActive={side === mockupAngle}
                      onClick={() => handleAngleChange(side)}
                      isDisabled={loading}
                    >
                      {side} Side
                    </Tag>
                  );
                })}
              </Tags>
            )}
            {/* Mockup sides */}
            <MockupImageSelector
              className="show-no-scrollbar"
              onScroll={handleScroll}
              ref={scrollRef}
            >
              {(productType === ProductType.Mug
                ? mockups.filter(
                    (mockup) =>
                      mockup.id.toString().split('-').slice(2).join('-') ===
                      selectedVariant?.label.replace(/\//g, '-'),
                  )
                : mockups.filter((obj) => {
                    const [, id] = obj.id.toString().split('-');
                    const combinedId = `${mockupAngle}${selectedVariant?.colorHex}-${id}`;
                    return obj.id === combinedId;
                  })
              )?.map((item: MockupData) => {
                const disableSelection =
                  selectedImages.length >= 10 && !item.selected;
                return (
                  <ImageBox
                    isClickable={true}
                    key={item.id}
                    image={item.image}
                    boxHeight="154px"
                    disabled={disableSelection}
                    className={
                      productType === ProductType.Mug ? ' mug-image-box' : ''
                    }
                    onClick={() => {
                      handleSelect(item.id);
                    }}
                  >
                    {(userInfo.email.includes('everbee.io') ||
                      userInfo.email.includes('abhi18december11@gmail.com')) &&
                      !(productType === ProductType.Mug) && (
                        <EditMockupIcon
                          className="adjust-mockup-btn"
                          onClick={() =>
                            openLinkInNewTab(
                              `https://mockup-print-a5db5cb43e90.herokuapp.com/image_posts/${item.id
                                .toString()
                                .split('-')
                                .pop()}`,
                            )
                          }
                        />
                      )}
                    <Checkbox
                      className="image-item"
                      id={`mockup-image-${item.id}`}
                      checked={item.selected}
                      disabled={disableSelection}
                      onChange={(value) => {
                        handleSelect(item.id, value);
                      }}
                    />
                  </ImageBox>
                );
              })}

              {loading ? <SmallLoader text="Loading images..." /> : null}
            </MockupImageSelector>
            {!isDefaultMockups && !(productType === ProductType.Mug) && (
              <Typography
                text="More Mockups coming soon!!!!"
                fontSize="20px"
                className="mockup-text"
                primaryColorValue={600}
              />
            )}
          </>
        ) : (
          <MyMockups isSizeCharts modelNumber={modelNumber} />
        )}

        {!isTablet && (
          <CTAButtonGroup>
            <Button
              appearance="secondary"
              label="Save"
              disabled={selectedImages.length === 0 || !dataChanged}
              onClick={() =>
                setUploadState({ triggered: true, action: 'save' })
              }
            />
            <Button
              appearance="primary"
              label="Next"
              disabled={selectedImages.length === 0}
              onClick={() =>
                setUploadState({ triggered: true, action: 'next' })
              }
            />
          </CTAButtonGroup>
        )}
      </Section>
      {showSnapModal && (
        <MugPreviewModal
          selectedColor={variants[0]}
          isColoredMug={isColoredMug}
          onClose={() => setShowSnapModal(false)}
          snapImages={snapImages}
          setSnapImages={setSnapImages}
          variants={variants}
          model={model === MugType._15oz ? MugType._15oz : MugType._11oz}
          isBlackMug={isBlackMug}
        />
      )}
    </>
  );
};

export default RightSideBar;

const Section = styled.div`
  position: relative;
  height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  color: ${colors.grey[900]};
  .adjust-mockup-btn {
    background-color: ${colors.blue[600]};
    border-radius: 3px;
    position: absolute;
    left: 10px;
    top: 8px;
    width: 24px;
  }
  .mockup-text {
    text-align: center;
    transform: rotate(-12deg);
    margin-top: 4rem;
  }
  .colors-wrapper {
    border: 1px solid ${colors.grey[300]};
    background: ${colors.grey[100]};
    border-radius: 6px;
    padding: 10px;
    margin: 10px 0;
  }
  .create-more-btn {
    padding: 4px 8px;
  }
  .create-mockup-btn {
    width: 40%;
    padding: 4px 8px;
  }
`;

const Heading = styled.div`
  font-size: 18px;
  line-height: 28px;
  margin: 12px 0;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 8px;
  margin-bottom: 3px;
`;

const Tag = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  padding: 4px 12px 6px;
  font-size: 14px;
  border: 1px solid ${colors.grey[300]};
  border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};
  ${(props) =>
    props.isActive &&
    css`
      background-color: ${colors.blue[100]};
      border: 1px solid ${colors.blue[500]};
    `}
`;

const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  gap: 1.4rem;
  font-size: 14px;

  & > div:first-child {
    width: calc(100% - 20px);
  }
`;

const MockupImageSelector = styled.div`
  display: flex;
  justify-content: space-between;
  /* height: 320px; */
  gap: 1.2rem;
  padding: 8px 0;
  margin: 8px 0;
  flex-wrap: wrap;
  overflow-y: scroll;

  & > div {
    width: calc(50% - 1rem);
  }

  .image-checkbox {
    position: absolute;
  }

  .mockup-loader {
    width: 100%;
  }
`;

const CTAButtonGroup = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
  & > button {
    flex: 1;
  }
`;

const mockupTabs: Option[] = [
  { label: 'Previews', value: 'mockupGenerator' },
  { label: 'My Images', value: 'myMockups' },
  { label: 'Size charts', value: 'sizeCharts' },
];
