// ShippingDetailsStep .js

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlexBox } from '../common/wrappers';
import { CalculatorIcon } from '../../assets/icons';
import { useAppSelector } from '../../store/hooks';
import {
  getSampleOrderData,
  setRecipient,
  setAddress,
  setTotalCost,
} from '../../store/slices/sampleOrderSlice';
import Typography from '../typography';
import Input from '../inputs';
import Button from '../common/button';
import { useGetShippingCostMutation } from '../../store/services/orders';
import { Address, Recipient } from '../../interface/create-order-interface';
import { SelectList } from '../common/elements';
import SmallLoader from '../common/small-loader';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { generateSampleOrderShippingCostPayload } from './utils';

interface Props {
  setRecalculateShipping: (value: boolean) => void;
  setIsShippingPageValid: (value: boolean) => void;
}
const ShippingDetailsStep: React.FC<Props> = ({
  setRecalculateShipping,
  setIsShippingPageValid,
}) => {
  const { recipient, shippingAddress, selectedProducts, totalCost } =
    useAppSelector(getSampleOrderData);
  const dispatch = useDispatch();
  const [getShippingCost, { isLoading }] = useGetShippingCostMutation();
  const [error, setErrors] = useState({
    phone: false,
    email: false,
  });
  const [isInitialRender, setIsInitialRender] = useState(true);
  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone: string) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  const handleRecipientChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: !isValidEmail,
      }));
    }
    dispatch(setRecipient({ ...recipient, [name]: value }));
  };
  const handleCalculateShipping = async () => {
    setRecalculateShipping(false);
    const products = generateSampleOrderShippingCostPayload(selectedProducts);
    try {
      const res = await getShippingCost({ products });
      if ('data' in res) {
        dispatch(
          setTotalCost({ ...totalCost, shippingCost: res.data.shippingCost }),
        );
      }
      setIsShippingPageValid(false);
    } catch (error) {}
  };
  const handleShippingAddressChange = (field: any, value: any) => {
    if (field === 'zipCode') {
      const validZipcode = /^\d+$/.test(value);
      if (!validZipcode) {
        if (value === '') {
          dispatch(
            setAddress({
              address: { ...shippingAddress, [field]: '' },
              isBilling: false,
            }),
          );
        }
        return;
      }
    }

    const newAddress =
      field === 'country' && value === shippingAddress.country
        ? { ...shippingAddress, [field]: '' }
        : { ...shippingAddress, [field]: value };
    dispatch(setAddress({ address: newAddress, isBilling: false }));
  };

  const checkAddressCompleteness = useCallback(() => {
    const isShippingInfoFilled = [
      'firstLine',
      'city',
      'state',
      'zipCode',
      'country',
    ].every((field) => shippingAddress[field as keyof Address]?.trim() !== '');
    const isRecipientInfoFilled = ['fullName', 'email'].every(
      (field) => recipient[field as keyof Recipient]?.trim() !== '',
    );
    return (
      isShippingInfoFilled &&
      isRecipientInfoFilled &&
      recipient.phone.length > 10
    );
  }, [recipient, shippingAddress]);
  const handlePhoneChange = (e: any) => {
    if (!isInitialRender) {
      dispatch(setRecipient({ ...recipient, phone: e }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: !isPhoneValid(e),
      }));
    }
  };
  useEffect(() => {
    setIsShippingPageValid(
      !checkAddressCompleteness() ||
        selectedProducts.length === 0 ||
        Object.values(error).some((item) => item),
    );
  }, [
    error,
    selectedProducts.length,
    setIsShippingPageValid,
    recipient,
    checkAddressCompleteness,
  ]);

  return (
    <>
      {isLoading && <SmallLoader />}
      <div className="b-r-6px grey-border address-wrapper">
        <Typography
          text="Recipient"
          fontSize="18px !important"
          fontWeight="500"
          secondaryColorValue={900}
        />
        <FlexBox
          justifyContent="space-between"
          alignItems="center"
          className="w-100 b-t recipient-wrapper"
          gap="8px"
        >
          <FlexBox direction="column" gap="0.6rem" className="w-50">
            <Typography fontSize="14px" text="Name" />
            <Input
              onChange={handleRecipientChange}
              value={recipient.fullName}
              name="fullName"
              placeholder="Enter name"
            />
          </FlexBox>
          <FlexBox direction="column" gap="0.6rem">
            <Typography fontSize="14px" text="Phone" />

            <FlexBox direction="column" style={{ height: '37px' }}>
              <PhoneInput
                value={recipient.phone}
                name="phone"
                onChange={handlePhoneChange}
                placeholder="Enter phone"
              />
              {error.phone && (
                <div className="fs-12 warning-color">Invalid Phone number</div>
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox
          justifyContent="space-between"
          className="w-100"
          direction="column"
          gap="0.6rem"
          style={{ marginTop: '8px' }}
        >
          <Typography fontSize="14px" text="Email" />
          <Input
            value={recipient.email}
            name="email"
            onChange={handleRecipientChange}
            placeholder="Enter email"
            className={error.email ? 'red-border' : ''}
          />
        </FlexBox>
      </div>
      <div className="b-r-6px grey-border" style={{ padding: '20px 10px 0' }}>
        <Typography text="Shipping address" fontSize="18px" />
        <AddressForm
          address={shippingAddress}
          handleAddressChange={handleShippingAddressChange}
          borderRequired={true}
        />
        <Button
          appearance="secondary"
          onClick={handleCalculateShipping}
          label={'Calculate Shipping'}
          icon={CalculatorIcon}
          disabled={
            !checkAddressCompleteness() ||
            selectedProducts.length === 0 ||
            Object.values(error).some((item) => item)
          }
        />
      </div>
    </>
  );
};

export default ShippingDetailsStep;

interface ShippingAddressFormProps {
  address: Address;
  handleAddressChange: (field: keyof Address, value: string) => void;
  borderRequired?: boolean;
}

export const AddressForm: React.FC<ShippingAddressFormProps> = ({
  address,
  handleAddressChange,
  borderRequired,
}) => {
  return (
    <>
      <FlexBox
        direction="column"
        gap="0.6rem"
        className={`shipping-wrapper ${
          borderRequired ? 'b-t' : ''
        }`}
      >
        <Typography fontSize="14px" text="Street Address" />
        <Input
          onChange={(e) => handleAddressChange('firstLine', e.target.value)}
          value={address.firstLine}
          name="address"
          placeholder="Enter Street Address"
        />
      </FlexBox>
      <FlexBox gap="2rem" style={{ margin: '10px 0' }}>
        <FlexBox direction="column" gap="0.6rem" className="w-50">
          <Typography fontSize="14px" text="City" />
          <Input
            onChange={(e) => handleAddressChange('city', e.target.value)}
            value={address.city}
            name="city"
            placeholder="Enter city"
          />
        </FlexBox>
        <FlexBox direction="column" gap="0.6rem" className="w-50">
          <Typography fontSize="14px" text="State" />
          <Input
            onChange={(e) => handleAddressChange('state', e.target.value)}
            value={address.state}
            name="state"
            placeholder="Enter State"
          />
        </FlexBox>
      </FlexBox>
      <FlexBox gap="2rem" style={{ margin: '10px 0' }}>
        <FlexBox direction="column" gap="0.6rem" className="w-50">
          <Typography fontSize="14px" text="Zip" />
          <Input
            onChange={(e) => handleAddressChange('zipCode', e.target.value)}
            value={address.zipCode}
            name="zipcode"
            placeholder="Enter ZIP"
          />
        </FlexBox>
        <FlexBox direction="column" gap="0.6rem" className="w-50">
          <Typography fontSize="14px" text="Country" />
          <SelectList
            items={countryList}
            onChange={(value) => handleAddressChange('country', value)}
            selectedValue={address.country}
            width="-webkit-fill-available"
            label="Select Country"
          />
        </FlexBox>
      </FlexBox>
    </>
  );
};

const countryList = [
  {
    label: 'USA',
    value: 'US',
  },
];
