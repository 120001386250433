import { Canvas } from 'fabric/fabric-impl';
import { fabric } from 'fabric';
import { colors } from '../../../../assets/colors';
import { ProductName } from '../../../../interface/product-interface';
export const applyMugCanvas = (
  canvas: Canvas,
  data: any,
  boundaryDiv: HTMLDivElement,
) => {
  const extraAreaInPXForInactiveFeel = 25;
  const inactiveAreaSize = 20;
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();
  boundaryDiv.style.boxShadow =
    data.editorSubType === ProductName.BlackMug
      ? `inset 0 0 0 ${inactiveAreaSize}px #0a0909`
      : `inset 0 0 0 ${inactiveAreaSize}px #F3F9FF`;
  boundaryDiv.style.top = `${extraAreaInPXForInactiveFeel}px`;
  boundaryDiv.style.left = `${extraAreaInPXForInactiveFeel}px`;
  boundaryDiv.style.width = `${
    canvasWidth - extraAreaInPXForInactiveFeel * 2
  }px`;
  boundaryDiv.style.height = `${
    canvasHeight - extraAreaInPXForInactiveFeel * 2
  }px`;
  boundaryDiv.style.outline =
    data.editorSubType === ProductName.BlackMug
      ? '4px solid black'
      : '4px solid #3D98F2';
  boundaryDiv.className = 'editor-design-inactive-area';
  let artBoard: any = new fabric.Rect({
    left: extraAreaInPXForInactiveFeel - 4,
    top: extraAreaInPXForInactiveFeel - 4,
    width: canvasWidth - extraAreaInPXForInactiveFeel * 2 + 4,
    height: canvasHeight - extraAreaInPXForInactiveFeel * 2 + 4,
    stroke: colors.grey[700],
    fill: 'black',
    selectable: false,
    hoverCursor: 'default',
    evented: false,
    opacity: 1,
    name: 'boundary-rect',
  });
  canvas.clipPath = artBoard;
  canvas.remove(artBoard);
  canvas.requestRenderAll();

  var centralBox = new fabric.Rect({
    left: extraAreaInPXForInactiveFeel + inactiveAreaSize,
    top: extraAreaInPXForInactiveFeel + inactiveAreaSize,
    width:
      canvasWidth - extraAreaInPXForInactiveFeel * 2 - inactiveAreaSize * 2,
    height:
      canvasHeight - extraAreaInPXForInactiveFeel * 2 - inactiveAreaSize * 2,

    fill: '',
    selectable: false,
    name: 'border-canvas',
    stroke: 'black',
    strokeWidth: 1,
    strokeDashArray: [10, 10],
    evented: false,
    backgroundColor:
      data.editorSubType === ProductName.BlackMug ? 'black' : 'white',
  });
  canvas.add(centralBox);
  canvas.sendToBack(centralBox);
  canvas.requestRenderAll();

  const borderRect = new fabric.Rect({
    left: extraAreaInPXForInactiveFeel - 2,
    top: extraAreaInPXForInactiveFeel - 2,
    width: canvasWidth - extraAreaInPXForInactiveFeel * 2 + 2,
    height: canvasHeight - extraAreaInPXForInactiveFeel * 2 + 2,

    fill: '',
    stroke: data.editorSubType === ProductName.BlackMug ? 'white' : '#3D98F2',
    strokeWidth: 4,
    selectable: false,
    evented: false,
    name: 'blue-mug-order',
  });
  canvas.add(borderRect);
  canvas.sendToBack(borderRect);
  canvas.requestRenderAll();
};

export const CAMERA_SETTINGS = {
  '15OZ': {
    position: [50, 10, 30],
    fov: 3.4,
  },
  '11OZ': {
    position: [14, -2, 20],
    fov: 0.5,
  },
};

export const MugModels = {
  '15OZ': [
    { geometryKey: 'mesh_0', materialKey: '01 - Default' },
    { geometryKey: 'mesh_0_1', materialKey: '02 - Default' },
    { geometryKey: 'mesh_0_2', materialKey: '03 - Default' },
    { geometryKey: 'mesh_0_3', materialKey: '04 - Default' },
    { geometryKey: 'mesh_0_4', materialKey: '05 - Default' },
  ],
  '11OZ': [
    { geometryKey: 'Mesh', materialKey: 'Ceramic' },
    { geometryKey: 'Mesh_1', materialKey: '#placeholder_front' },
    { geometryKey: 'Mesh_2', materialKey: 'Accent' },
  ],
  '11OZBlack': [
    { geometryKey: 'Mug_11oz_LOW_HI001', materialKey: 'Ceramic' },
    { geometryKey: 'Mug_11oz_LOW_HI001_1', materialKey: '#placeholder_front' },
  ],
};

export const MeshConstants = {
  '15OZ': {
    colorMeshes: ['mesh_0_3', 'mesh_0_4', 'mesh_0_5', 'mesh_0_2'],
    textureMesh: 'mesh_0_1',
  },
  '11OZ': {
    colorMeshes: ['Mesh_2'],
    textureMesh: 'Mesh_1',
  },
  '11OZBlack': {
    colorMeshes: ['Mug_11oz_LOW_HI001_1'],
    textureMesh: 'Mug_11oz_LOW_HI001_1',
  },
  '15OZBlack': {
    colorMeshes: ['mesh_0', 'mesh_0_3', 'mesh_0_4', 'mesh_0_5', 'mesh_0_2'],
    textureMesh: 'mesh_0_1',
  },
};
