import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../common/button';
import { colors } from '../../../assets/colors';
import { FlexBox } from '../../common/wrappers';
import { useAppSelector } from '../../../store/hooks';
import { getDetails, productDetails } from '../../../store/slices/detailsSlice';
import { Status } from '../../../interface';

import Modal from '../../common/modal';
import VariantTable from '../variant-component/VariantTable';
import { isDescriptionAdded } from '../utils';
import {
  getUtilityData,
  setIsDataSaved,
} from '../../../store/slices/utilitySlice';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// commented code in this file is for future uses
// import { getUserData } from '../../../store/slices/authSlice';
interface Props {
  isMockupsUploading: boolean;
  handleUpdateProduct: (flag: boolean) => Promise<void>;
}
const DetailsFooter: React.FC<Props> = ({
  isMockupsUploading,
  handleUpdateProduct,
}) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  // const { userInfo } = useAppSelector(getUserData);
  const [currency, setCurrency] = useState({
    current: 'USD',
    shopCurrency: 'USD',
  });
  // const [modalVisited, setModalVisited] = useState<boolean>(false);
  const { dataChanged } = useAppSelector(getUtilityData);

  const details = useAppSelector(productDetails);
  const { images, status, isPersonalizable, personalizationInstructions } =
    useAppSelector(getDetails);
  const detailsCopy = { ...details };
  delete detailsCopy.descriptionResponse;
  const location = useLocation();
  const dispatch = useDispatch();
  const getEmptyFields = () => {
    const { variantsVisibility, syncingOptions, shippingProfile } =
      detailsCopy.publishingSettings;
    const missingFields = [];
    if (!variantsVisibility || !syncingOptions || !shippingProfile) {
      missingFields.push('Publishing Settings');
    }
    if (!detailsCopy.title) {
      missingFields.push('Title');
    }

    if (detailsCopy.tags.length === 0) {
      missingFields.push('Tags');
    }

    if (!isDescriptionAdded(detailsCopy.description)) {
      missingFields.push('Description');
    }
    if (images.length === 0 && !isMockupsUploading) {
      missingFields.push('Images');
    }

    return missingFields;
  };

  const isButtonDisabled = () => {
    if (
      detailsCopy.title &&
      detailsCopy.tags.length > 0 &&
      isDescriptionAdded(detailsCopy.description) &&
      detailsCopy.publishingSettings.variantsVisibility &&
      detailsCopy.publishingSettings.syncingOptions &&
      detailsCopy.publishingSettings.shippingProfile &&
      !isMockupsUploading &&
      images.length !== 0 &&
      ((isPersonalizable && personalizationInstructions) || !isPersonalizable)
    ) {
      return false;
    }
    return true;
  };

  const closeVariantModal = () => {
    setCurrency({ ...currency, shopCurrency: 'USD' });
    // setModalVisited(true);
  };
  useEffect(() => {
    return () => {
      dispatch(setIsDataSaved(false));
    };
  }, [dispatch, location.pathname]);

  return (
    <StyledWrapper>
      {getEmptyFields().length > 0 && showMessage && (
        <p
          className="fs-14 missing-details-error"
          style={{ color: colors.red[600] }}
        >{`${getEmptyFields().join(
          ', ',
        )} missing, please check the details again.`}</p>
      )}
      <FlexBox gap="1rem" className="buttons-wrapper">
        <Button
          label="Save"
          disabled={!dataChanged || isMockupsUploading}
          size="medium"
          onClick={() =>
            handleUpdateProduct(false).then(() => {
              dispatch(setIsDataSaved(true));
            })
          }
          appearance="secondary"
          // setShowMessage={(value) => setShowMessage(value)}
        />

        <Button
          label={
            status === Status.Draft ||
            status === Status.Unpublished ||
            status === Status.Error
              ? 'Publish'
              : 'Publish changes'
          }
          size="medium"
          disabled={isButtonDisabled()}
          onClick={() => {
            handleUpdateProduct(true);
          }}
          appearance="primary"
          setShowMessage={(value) => setShowMessage(value)}
        />
      </FlexBox>
      {currency.shopCurrency !== currency.current && (
        <Modal
          size="auto"
          onClose={closeVariantModal}
          title="It seems that your store currency is different from USD. Please review prices in your Store Currency"
        >
          <VariantTable
            height="100%"
            shopCurrency={currency.shopCurrency}
            currentCurrency={currency.current}
          />
          <Button
            label={
              status === Status.Draft ||
              status === Status.Unpublished ||
              status === Status.Error
                ? 'Publish'
                : 'Publish changes'
            }
            size="medium"
            onClick={() => {
              handleUpdateProduct(true);
              closeVariantModal();
            }}
            appearance="primary"
            setShowMessage={(value) => setShowMessage(value)}
            className="float-right"
          />
        </Modal>
      )}
    </StyledWrapper>
  );
};

export default DetailsFooter;

const StyledWrapper = styled.div`
  max-height: 4.25rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 12;
  ::before {
    content: '';
  }
  .buttons-wrapper {
    margin-right: 1rem;
    margin-bottom: 9px;
  }
  .buttons-wrapper > button {
    width: 160px;
    height: 36px;
  }
`;
