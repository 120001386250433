import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../../common/wrappers';
import Dropzone from 'react-dropzone';
import {
  DropzonePlaceholder,
  FirstMugAngle,
  SecondMugAngle,
  ThirdMugAngle,
} from '../../../../assets/icons';
import { Canvas, IEvent } from 'fabric/fabric-impl';
import { onDropRejectedHandler, uploadToGallery } from '../../utils';
import MugViewer from './MugViewer';
import Loader from '../../../common/loader';
import { MAX_DESIGN_FILE_SIZE } from '../../../../constants/designTabs';
import {
  MugDimensions,
  MugType,
} from '../../../../interface/product-interface';

interface Props {
  isTablet: boolean;
  addToGallery: (files: any) => void;
  files: any[];
  ToolBar: ReactNode;
  UndoRedoComponent: ReactNode;
  drawingBoard: Canvas | undefined;
  image: Blob;
  selectedColor: any;
  isColoredMug: boolean;
  addImageLoader: boolean;
  mugCanvasRef: React.RefObject<HTMLDivElement>;
  model: string;
  isBlackMug: boolean | undefined;
}

const MugsEditor: React.FC<Props> = ({
  isTablet,
  addToGallery,
  files,
  ToolBar,
  UndoRedoComponent,
  drawingBoard,
  image,
  selectedColor,
  isColoredMug,
  addImageLoader,
  mugCanvasRef,
  model,
  isBlackMug,
}) => {
  useEffect(() => {
    const bringDividerLinesToFront = () => {
      const lines = drawingBoard?.getObjects('line');
      const guideLine = lines?.filter((line) => line.name === 'guideLines');
      if (guideLine) {
        guideLine.forEach((line) => {
          line.bringToFront();
        });
      }
    };

    if (drawingBoard) {
      bringDividerLinesToFront();
      drawingBoard?.on('object:added', (e: IEvent) => {
        bringDividerLinesToFront();
      });
    }
  }, [drawingBoard]);

  return (
    <div className="mug-editor-wrapper">
      {addImageLoader && <Loader disableBackDrop={true} />}

      <Wrapper isTablet={isTablet}>
        {/*Drawing section start */}
        <DrawingWrapper>
          <FlexBox
            style={{
              width: '100%',
              height: 'auto',
            }}
            justifyContent="center"
            alignItems="center"
            direction="column"
            gap="2rem"
          >
            <FlexBox
              justifyContent="center"
              direction="column"
              alignItems="center"
              className="canvas"
              id="canvas-parent"
              style={{
                backgroundColor: 'white',
              }}
              ref={mugCanvasRef}
            >
              <Dropzone
                onDropRejected={onDropRejectedHandler}
                maxSize={MAX_DESIGN_FILE_SIZE}
                multiple={false}
                maxFiles={1}
                onDrop={(acceptedFiles, fileRejections) => {
                  if (fileRejections.length > 0) return;
                  uploadToGallery(
                    acceptedFiles,
                    addToGallery,
                    drawingBoard,
                    files,
                  );
                }}
                accept={{
                  'image/png': ['.png'],
                  'image/jpg': ['.jpg'],
                  'image/jpeg': ['.jpeg'],
                  'image/svg': ['.svg'],
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <section
                      className="w-100 h-100 absolute"
                      style={{
                        display: files.length === 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                    >
                      <FlexBox
                        {...getRootProps()}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className="w-100 h-100"
                        gap="0.5rem"
                      >
                        <input
                          {...getInputProps()}
                          style={{ display: 'none' }}
                        />
                        <img src={DropzonePlaceholder} alt="Dropzone" />
                        <p className="fs-16 m-0">
                          Drag and drop your design here
                        </p>
                      </FlexBox>
                    </section>
                  </>
                )}
              </Dropzone>
              <canvas
                height={
                  model === MugType._15oz
                    ? isBlackMug
                      ? MugDimensions._15OZ_Black
                      : MugDimensions._15OZ
                    : isBlackMug
                    ? MugDimensions._11OZ_Black
                    : MugDimensions._11OZ
                }
                width={700}
                id="drawingArea"
              />

              {ToolBar}
            </FlexBox>
            <FlexBox className="image-box">
              <img
                src={FirstMugAngle}
                alt="mug-img"
                className="first-mug-angle"
              />
              <img src={SecondMugAngle} alt="mug-img" className="mug-angle" />
              <img src={ThirdMugAngle} alt="mug-img" className="mug-angle" />
            </FlexBox>
          </FlexBox>
        </DrawingWrapper>
        {/*Drawing section ends */}
      </Wrapper>
      <div>
        {image && (
          <MugViewer
            image={image}
            selectedColor={selectedColor}
            isColoredMug={isColoredMug}
            isBlackMug={isBlackMug}
            model={model === MugType._15oz ? MugType._15oz : MugType._11oz}
          />
        )}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '70px',
          left: '10px',
          userSelect: 'none',
        }}
        className="undo-redo-wrapper"
      >
        {UndoRedoComponent}
      </div>
    </div>
  );
};

export default MugsEditor;

const Wrapper = styled.div<{ isTablet: boolean }>`
  padding: 16px 32px;
  display: flex;
  overflow: hidden;
  height: fit-content;
  justify-content: center;
  gap: ${(props) => props.isTablet && '5rem'};
  flex-direction: column;
`;

const DrawingWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  position: relative;
  .canvas {
    position: relative !important;
  }
  .image-box {
    .mug-angle {
      margin-left: 128px;
    }
    .first-mug-angle {
      // margin-left: 82px;
    }
  }
`;
