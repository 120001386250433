import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { FlexBox } from '../../../common/wrappers';
import RangeSlider from '../../../catalog/common/range-slider';
import Typography from '../../../typography';
import { OrbitControls } from '@react-three/drei';
import { colors } from '../../../../assets/colors';
import { SnapIcon, TheeDIcon } from '../../../../assets/icons';
import { CustomBtnWithIcon } from '../../../common/elements';
import Mug3d from './Mug3d';
import { CAMERA_SETTINGS } from './utils';

interface MugViewerProps {
  image: Blob | string;
  selectedColor: any;
  isColoredMug: boolean;
  setSnapImages?: any;
  frontDesign?: string;
  snapImages?: any;
  model: '15OZ' | '11OZ';
  isBlackMug?: boolean | undefined;
}
const MugViewer: React.FC<MugViewerProps> = ({
  image,
  selectedColor,
  isColoredMug,
  setSnapImages,
  frontDesign,
  snapImages,
  model,
  isBlackMug,
}) => {
  const [rotationY, setRotationY] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const mugRef = useRef<{ takeScreenshot: () => void }>(null);
  const controlsRef = useRef<any>(null);
  const handleSliderChange = (e: any) => {
    setRotationY(parseInt(e.target.value, 10));
    const newPercentage = Math.round(
      (parseInt(e.target.value, 10) / 360) * 100,
    );
    setPercentage(newPercentage);
  };

  const [snapClicked, setSnapClicked] = useState(false);
  useEffect(() => {
    if (snapClicked) {
      setSnapClicked(false);
    }
  }, [snapClicked]);
  const handleScreenshot = () => {
    if (mugRef.current) {
      mugRef.current.takeScreenshot();
    }
  };

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  }, [rotationY]);
  return (
    <Container isModal={setSnapImages ? true : false}>
      <Canvas
        camera={{
          position: CAMERA_SETTINGS[model].position as [number, number, number],
          fov: CAMERA_SETTINGS[model].fov,
        }}
        className="mugs-canvas"
      >
        <ambientLight intensity={0.6} />
        <directionalLight position={[10, 10, 10]} intensity={0.6} />
        <OrbitControls
          ref={controlsRef}
          enableZoom={false}
          enablePan={false}
          maxPolarAngle={Math.PI / 1.5}
          minPolarAngle={Math.PI / 3}
        />

        {/* Your 3D Model */}
        <Mug3d
          rotationY={rotationY}
          image={image}
          selectedColor={selectedColor}
          isColoredMug={isColoredMug}
          snapClicked={snapClicked}
          setSnapImages={setSnapImages}
          frontDesign={frontDesign}
          ref={mugRef}
          model={model}
          isBlackMug={isBlackMug}
        />
        {/* <ContactShadows position-y={-0.5} opacity={0.1} blur={2} /> */}
      </Canvas>
      {snapImages && (
        <FlexBox className="grey-bg count" justifyContent="flex-start">
          <span className="fs-14"> You can make up to 10 pictures</span>
        </FlexBox>
      )}
      <FlexBox
        gap="1rem"
        justifyContent={setSnapImages ? 'space-between' : 'center'}
        alignItems="center"
        className="grey-bg p-10px"
      >
        {setSnapImages && (
          <>
            <FlexBox justifyContent="center" alignItems="center" gap="10px">
              <img src={TheeDIcon} alt="3d Icon" height={25} width={25} />
              <p>3D</p>
            </FlexBox>
          </>
        )}
        <div style={{ width: setSnapImages ? '40%' : '75%' }}>
          <RangeSlider
            value={rotationY}
            min={0}
            max={360}
            onChange={handleSliderChange}
            aria-label="Rotation Slider"
          />
        </div>
        {!setSnapImages ? (
          <Typography text={`${percentage}%`} fontSize="12px" />
        ) : (
          <CustomBtnWithIcon
            onClick={handleScreenshot}
            style={{
              padding: '3px',
              margin: '0',
            }}
          >
            <img src={SnapIcon} alt="Text" />
            <p style={{ fontSize: '14px', margin: '0 0 0 3px' }}>Take a Snap</p>
          </CustomBtnWithIcon>
        )}
      </FlexBox>
    </Container>
  );
};

export default MugViewer;
const Container = styled.div<{ isModal: boolean }>`
  height: 206px;
  width: ${(props) => (props.isModal ? '100%' : '250px')};
  margin: ${(props) => (props.isModal ? '0 auto 70px' : '0 auto 90px')};
  .mugs-canvas {
    canvas {
      height: 300px !important;
    }
  }
  .mugs-canvas div:first-child {
    height: unset !important;
    width: unset !important;
  }
  .MuiSlider-rail {
    border-radius: var(--Spacing-1, 4px);
    background: var(--grey-400, #ccc);
    color: ${colors.grey[400]};
  }
  .MuiSlider-thumb {
    width: 28px;
    border-radius: var(--Spacing-1, 4px);
    background: ${colors.grey[600]};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px,
      rgba(0, 0, 0, 0.15) 0px 1px 1px 0px !important;
    height: 10px;
  }
  .MuiSlider-track {
    border-radius: var(--Spacing-1, 4px);
    background: var(--grey-400, #ccc);
    color: ${colors.grey[400]};
  }
  .count {
    padding: 10px 10px 0 10px;
  }
  .grey-bg {
    background-color: ${colors.grey[100]};
  }
`;
