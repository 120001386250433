import React from 'react';
import Modal from '../common/modal';
import { FlexBox } from '../common/wrappers';

import Typography from '../typography';
import { GreenTickIcon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { routeNames } from '../../constants/routesPath';
import styled from 'styled-components';

interface SuccessSampleOrderModalProps {
  onClose: () => void;
  personalizedSampleOrderId: string | null;
}

const SuccessSampleOrderModal: React.FC<SuccessSampleOrderModalProps> = ({
  onClose,
  personalizedSampleOrderId,
}) => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <Modal
        title={''}
        size="small"
        onClose={onClose}
        className="success-sample-order-modal"
      >
        <FlexBox
          direction="column"
          gap="1rem"
          className="mainsection"
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: '18%' }}
        >
          <img src={GreenTickIcon} alt="success" />
          <Typography
            fontSize="20px"
            text="Thank You!"
            secondaryColorValue={900}
            fontWeight="900"
          />
          <Typography
            fontSize="14px"
            className="text-align-center"
            text={
              personalizedSampleOrderId ? (
                <>
                  We have received your order. Please{' '}
                  <span
                    className="underline-text"
                    onClick={() =>
                      navigate(
                        `${routeNames.orders}/${personalizedSampleOrderId}`,
                      )
                    }
                  >
                    personalize your order
                  </span>{' '}
                  for fulfilment.
                </>
              ) : (
                'We have received your order'
              )
            }
          />
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default SuccessSampleOrderModal;

const StyledWrapper = styled.div`
  .success-sample-order-modal {
    overflow: hidden !important;
  }
`;
