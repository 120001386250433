import dayjs from 'dayjs';
import { OrderResults } from '../interface/orders-interface';
import { nanoid } from '@reduxjs/toolkit';
export const getOrdersRowData = (array: OrderResults[]) => {
  var rowData: any[] = array.map((orderObj) => {
    return {
      id: nanoid(),
      ...orderObj,
      order: `Order # ${orderObj.storeId}${orderObj.orderId}`,
      createdAt: dayjs(orderObj.createdAt).format('LLLL'),
      productionDate: orderObj.productionDate
        ? dayjs(orderObj.productionDate).format('LL')
        : 'Not sent',
      shippedOn: orderObj?.shippedOn
        ? dayjs(orderObj?.shippedOn).format('MMM D YYYY, h:m A')
        : '',   
      customer: orderObj.customerName,
    };
  });
  return rowData;
};
