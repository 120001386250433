import React, { useMemo } from 'react';
import Button from '../../../common/button';
import Editor from '../../../editor';
import Modal from '../../../common/modal';
import { OpenEditorIcon } from '../../../../assets/icons';
import { OrderItem, OrderStatus } from '../../../../interface/orders-interface';
import { FlexBox } from '../../../common/wrappers';
import CustomToolTip from '../../../custom-tooltip';
import { ReactComponent as InfoIcon } from '../../../../assets/images/info-icon.svg';
import { SingleProductProperty } from '../../../../interface/product-interface';

interface Props {
  item: OrderItem;
  orderId: string;
  setShowPersonalizeEditorModal: (value: boolean) => void;
  showPersonalizeEditorModal: boolean;
  personalizationId?: string;
  status: string | undefined;
  variantProperties?: SingleProductProperty[];
  transactionId: any;
}

const PersonalizationEditor: React.FC<Props> = ({
  item,
  personalizationId,
  setShowPersonalizeEditorModal,
  showPersonalizeEditorModal,
  orderId,
  status,
  transactionId
}) => {
  const variantProperty = useMemo(() => {
    const { variantProperties, quantity } = item || {};

    const findPropertyValue = (propertyName: 'color' | 'size') =>
      variantProperties?.find(
        (property) => property.propertyName === propertyName,
      );

    const colorHex = findPropertyValue('color')?.meta?.colorHex || '';
    const size = findPropertyValue('size')?.propertyValue || '';

    return { colorHex, size, quantity };
  }, [item]);

  return (
    <>
      {item.personalization.required && (
        <FlexBox justifyContent="center" alignItems="center" gap="5px">
          <CustomToolTip
            title={
              <p className="box p-10px fs-12">
                <b>Instructions:</b> {item.personalization.instructions}
              </p>
            }
          >
            <InfoIcon width="34px" />
          </CustomToolTip>
          {(status === OrderStatus.PENDING_FULFILLMENT ||
            status === OrderStatus.PENDING_PERSONALIZATION) && (
            <Button
              appearance={item.personalization.done ? 'secondary' : 'primary'}
              onClick={() => setShowPersonalizeEditorModal(true)}
              label={item.personalization.done ? 'Open Editor' : 'Personalize'}
              icon={item.personalization.done ? OpenEditorIcon : ''}
            />
          )}
        </FlexBox>
      )}
      {showPersonalizeEditorModal && personalizationId === item.productId && transactionId === item.transactionId && (
        <Modal
          title={item.title}
          size="extraLarge"
          onClose={() => setShowPersonalizeEditorModal(false)}
          colorBoxItem={variantProperty}
          showColorBox={true}
          className={'personalize-editor'}
        >
          <Editor
            isTablet={false}
            isSellerEditor={true}
            productIdFromPersonalization={item.productId}
            transactionId={transactionId}
            ordersPrintProviderId={item.printProviderId}
            ordersCatalogId={item.catalogId}
            variantIdFromPeronalization={item.variantId}
            instructions={item.personalization?.instructions}
            editorDataFromProps={
              item.personalization?.editorData
                ? JSON.parse(item.personalization?.editorData)
                : undefined
            }
            setShowPersonalizeEditorModal={setShowPersonalizeEditorModal}
          />
        </Modal>
      )}
    </>
  );
};

export default PersonalizationEditor;
