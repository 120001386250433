import { fabric } from 'fabric';
import {
  innerAluminiumOvalPath,
  innerBellPath,
  innerBeneluxPath,
  innerBerlinPath,
  innerCirclePath,
  innerHeartPath,
  innerLondonPath,
  innerOvalPath,
  innerPraguePath,
  innerRectanglePath,
  innerSnowflakePath,
  innerStarPath,
  innerTaperedPath,
  innerTreePath,
  innnerCeramicStarPath,
  outerAluminiumOvalPath,
  outerBellPath,
  outerBeneluxPath,
  outerBerlinPath,
  outerCeramicStarPath,
  outerHeartPath,
  outerLondonPath,
  outerOvalPath,
  outerPraguePath,
  outerRectanglePath,
  outerSnowflakePath,
  outerStarPath,
  outerTaperedPath,
  outerTreePath,
} from './paths';

export const createCircleOrnamentCanvas = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();
  const outerCircleRadius = 240;
  const smallCircleRadius = 12;

  // Outer dashed circle
  var outerLayout = new fabric.Circle({
    left: (canvasWidth - outerCircleRadius * 2) / 2,
    top: (canvasHeight - outerCircleRadius * 2) / 2,
    radius: outerCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    startAngle: fabric.util.degreesToRadians(0),
    endAngle: fabric.util.degreesToRadians(20620),
    name: 'outer-path',
  });

  // Small cutout circle at the top
  const innerLayout = new fabric.Path(innerCirclePath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the inner path
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2 + 2,
  });

  // Update positions after setting
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - outerCircleRadius * 2) / 2 + 27,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(cutoutCircle);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createOvalOrnamentCanvas = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  // Get canvas dimensions
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 13;

  // Create the outer oval path object
  const outerLayout = new fabric.Path(outerOvalPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',

    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  // Create the inner oval path object
  const innerLayout = new fabric.Path(innerOvalPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',

    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);

  // Render all objects on the canvas (temporarily)
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer oval
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner oval
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2 + 32,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });

  canvas.sendToBack(cutoutCircle);
  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);
  canvas.requestRenderAll();
};

// Function to create a heart ornament canvas
export const createHeartOrnamentCanvas = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;

  const outerLayout = new fabric.Path(outerHeartPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    top: 75,
    left: 40,
    name: 'outer-path',
  });
  const innerLayout = new fabric.Path(innerHeartPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    top: 95,
    left: 60,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 4 - 30,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  // Add elements to the canvas
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.add(cutoutCircle);

  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer oval
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner oval
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  canvas.sendToBack(cutoutCircle);
  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);
  canvas.requestRenderAll();
};

export const createStarOrnamentCanvas = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;
  const outerLayout = new fabric.Path(outerStarPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerStarPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2 + 25,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 4 - 40,
    // top:
    //   (canvasHeight - outerBoundingRect.height) / 2 - outerBoundingRect.top / 2,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(cutoutCircle);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const getOrnamentsModel = (catalogId: string) => {
  if (catalogId === '663b4d932100a7981b60e308') {
    return 'OR-CIRCLE';
  } else if (catalogId === '663b52812100a7981b60e30c') {
    return 'OR-OVAL';
  } else if (catalogId === '663b54d82100a7981b60e310') {
    return 'OR-HEART';
  } else if (catalogId === '663b542b2100a7981b60e30e') {
    return 'OR-STAR';
  } else if (catalogId === '6718d747ba5098df97d3948d') {
    return 'ORA-TREE';
  } else if (catalogId === '6718d8c0ba5098df97d3948e') {
    return 'ORA-BELL';
  } else if (catalogId === '6718dc3cba5098df97d39491') {
    return 'ORA-BERLIN';
  } else if (catalogId === '6718d9adba5098df97d3948f') {
    return 'ORA-SNOWFLAKE';
  } else if (catalogId === '6718daa3ba5098df97d39490') {
    return 'ORC-SNOWFLAKE';
  } else if (catalogId === '6718dd1eba5098df97d39492') {
    return 'ORC-STAR';
  } else if (catalogId === '672a47b7755e8273d3247d32') {
    return 'ORA-TAPERED';
  } else if (catalogId === '672a46e9755e8273d3247d31') {
    return 'ORA-BENELUX';
  } else if (catalogId === '672a49f3755e8273d3247d34') {
    return 'ORA-PRAGUE';
  } else if (catalogId === '672a4c51755e8273d3247d35') {
    return 'ORA-OVAL';
  } else if (catalogId === '672a4907755e8273d3247d33') {
    return 'ORA-RECTANGLE';
  } else {
    return undefined;
  }
};

export const createTreeOrnament = (canvas: fabric.Canvas, color?: string) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;
  const outerLayout = new fabric.Path(outerTreePath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerTreePath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2 + 15,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 210,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createBellOrnament = (canvas: fabric.Canvas, color?: string) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;
  const outerLayout = new fabric.Path(outerBellPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerBellPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2 + 18,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 210,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createLondonOrnament = (canvas: fabric.Canvas, color?: string) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 13;
  const outerLayout = new fabric.Path(outerLondonPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerLondonPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2 + 20,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 190,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createSnowflakeOrnament = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;
  const outerLayout = new fabric.Path(outerSnowflakePath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerSnowflakePath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 150,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createBerlinOrnament = (canvas: fabric.Canvas, color?: string) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 15;
  const outerLayout = new fabric.Path(outerBerlinPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerBerlinPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 170,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createCeramicStarOrnament = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;
  const outerLayout = new fabric.Path(outerCeramicStarPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innnerCeramicStarPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2 + 25,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2 + 3,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 170,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createRectangleOrnament = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  console.log('color', color);
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 15;
  const outerLayout = new fabric.Path(outerRectanglePath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerRectanglePath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 195,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createTapredOrnament = (canvas: fabric.Canvas, color?: string) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;
  const outerLayout = new fabric.Path(outerTaperedPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerTaperedPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 160,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createBeneluxOrnament = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 15;
  const outerLayout = new fabric.Path(outerBeneluxPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerBeneluxPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 180,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createPragueOrnament = (canvas: fabric.Canvas, color?: string) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 15;
  const outerLayout = new fabric.Path(outerPraguePath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerPraguePath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 190,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createCeramicOvalOrnament = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 15;
  const outerLayout = new fabric.Path(outerAluminiumOvalPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerLayout = new fabric.Path(innerAluminiumOvalPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#ffffff',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 2 - 190,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  canvas.sendToBack(cutoutCircle);

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};
