import { Slider, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
interface Props {
  value: number[] | number;
  onChange: (event: Event, newValue: number | number[]) => void;
  max?: number;
  min?: number;
}
const RangeSlider: React.FC<Props> = ({ value, onChange, max, min }) => {
  const sliderTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          root: {
            color: '#1F76CC',
            height: 3,
            padding: '13px 0',
          },
          thumb: {
            height: 20,
            width: 20,
            backgroundColor: '#fff',
          },

          track: {
            height: 1,
          },
          rail: {
            color: '#d8d8d8',
            opacity: 1,
            height: 3,
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={sliderTheme}>
      <Slider
        value={value}
        onChange={onChange}
        disableSwap
        max={max}
        min={min}
        name="priceRange"
        getAriaLabel={(index: number) =>
          index === 0 ? 'Minimum price' : 'Maximum price'
        }
      />
    </ThemeProvider>
  );
};

export default RangeSlider;
