import { Address, Customer } from './create-order-interface';
import { SingleProductProperty } from './product-interface';
export interface ProductCost {
  shipping: number;
  tax: number;
  total: number;
  currencyCode?: string;
}
export interface Fees {
  listingFee: number;
  transactionFee: number;
  processingFee: number;
}

export interface OrderResults {
  _id: string;
  status: string;
  storeId: number;
  orderId: number;
  productionDate: string;
  shippedOn: Date | null;
  userId: string;
  isSampleOrder: boolean;
  createdAt: Date;
  updatedAt: Date;
  imageUrls: string[];
  totalCost: number;
  customerName: string;
}

export interface GetOrdersResponse {
  results: OrderResults[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
  statusTotals: StatusTotals[];
}

export interface GetOrdersQueryParams {
  storeId: string;
  search?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
  page: number;
  sortBy?: any;
  sortDirection?: any;
}
export interface OrdersFilter {
  searchText: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
}

export interface SortFilter {
  field: string;
  sort: 'asc' | 'desc';
}
export interface OrderIdQueryParam {
  orderId: string | undefined;
}

export interface UpdateOrderPayload {
  body: {
    shippingAddress?: Address;
    customer?: Customer;
  };
  orderId: string;
}
export enum OrderStatus {
  DRAFT = 'Draft',
  PENDING_PERSONALIZATION = 'Pending Personalization',
  READY = 'Ready for production',
  IN_PRODUCTION = 'In Production',
  READY_TO_SHIP = 'Ready to Ship',
  SHIPPED = 'Shipped',
  CANCELLED = 'Cancelled',
  EXCEPTION = 'Exception',
  PENDING_FULFILLMENT = 'Pending Fulfillment',
}

export interface CancelOrderPayload extends OrderIdQueryParam {
  reason: string;
}

export interface StatusTotals {
  status: string;
  count: number;
}

export interface ProductInfoFromOrder {
  productId: string;
  catalogSKUId: number;
  quantity: number;
  productColorID: number;
}

export enum StripeErrorCodes {
  EXPIRED_CARD = 'expired_card',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
}
enum DesignAreas {
  FRONT = 'frontDesign',
  BACK = 'backDesign',
}

type DesignFileUrls = {
  [key in DesignAreas]?: string;
};

type MockupUrls = {
  frontMockupURL?: string;
  backMockupURL?: string;
};

export interface PersonalizationInfoForProduct
  extends DesignFileUrls,
    MockupUrls {
  required: boolean;
  done: boolean;
  editorData?: string;
  instructions?: string;
}

export interface GetOrderDetailsResponse {
  data: {
    _id: string;
    status: string;
    orderId: number;
    storeId: number;
    customer: Customer;
    shippingAddress: Address;
    userId: string;
    productionCost: ProductCost;
    customerPaid: ProductCost;
    fees: Fees;
    containsNonEverbeePrintsItems: boolean;
    isSampleOrder: boolean;
    products: OrderItem[];
    productionDate: Date | null;
    shippedOn: Date | null;
  };
}

export interface OrderItem extends DesignFileUrls {
  supplierOrderId: string;
  title: string;
  brand: string;
  model: string | null;
  transactionId: number;
  variantId: string;
  printSKUId: string;
  quantity: number;
  personalization: PersonalizationInfoForProduct;
  printAreasForCatalog: string[];
  productId: string;
  printProviderId: string;
  catalogId: string;
  category: string;
  variantProperties: SingleProductProperty[];
  frontMockupURL: string | null;
  backMockupURL: string | null;
  trackingNumber: string | null;
  trackingLink: string | null;
  producedBy: string;
}

export interface UpdateProductPersonalizationPayload {
  orderId: string;
  supplierOrderId: string;
  transactionId: number;
  images: any;
  editorData: string;
}
