import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IObject, ToolBarProps } from '../../interface';
import { SelectList } from './elements';
import { FlexBox } from './wrappers';
import { debounce } from 'lodash';
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AspectRatioFitIcon,
  DeleteIcon,
  DuplicateIcon,
  FitScreenIcon,
  FlipHorizontalIcon,
  FlipVerticalIcon,
  // MoreIcon,
  TextColorIcon,
} from '../../assets/icons';
import { colors } from '../../assets/colors';
import {
  colorsPallete,
  deleteObj,
  duplicateObj,
  flipHorizontally,
  flipVertically,
} from '../editor/utils';
import { useAppSelector } from '../../store/hooks';
import { getObjects } from '../../store/slices/editor';
import { fontFamilies } from './fontFamilies';
import CustomToolTip from '../custom-tooltip';
import { ClickAwayListener } from '@mui/material';
import ColorPicker from 'react-pick-color';

const ToolBar = (props: ToolBarProps) => {
  const { type, drawingBoard, id: selectedElementId, top } = props;
  const [showColorsPopup, setShowColorsPopup] = useState(false);
  const [coords, setCoords] = useState<any>({ x: undefined, y: undefined });
  const toolbarRef = useRef<any>(null);
  const files = useAppSelector(getObjects);
  const element: any = drawingBoard?.getActiveObject();
  const height = element?.height;
  const width = element?.width;
  const isPersonalized = element?.personalizationData;
  let imageOptions: any = [];
  if (isPersonalized && type === 'custom-image') {
    imageOptions = element?.personalizationData.imageOptions;
  }

  const fontSizeOptions = [46, 58, 72, 92, 116, 146, 182, 230, 290, 364, 460];
  const getTopCoords = (object: IObject) => {
    const boundingBox = object?.getBoundingRect() ?? {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    };

    const topCoords = {
      x: boundingBox.left + boundingBox.width / 2,
      y: boundingBox.top,
    };
    return topCoords;
  };

  const alignText = (position: string) => {
    if (drawingBoard) {
      // if (drawingBoard.clipPath) {
        if (position === 'left') {
          element.set({
            textAlign: 'left',
          });
        } else if (position === 'center') {
          element.set({ textAlign: 'center' });
        } else {
          element.set({
            textAlign: 'right',
          });
        }
      // } else {
      //   if (drawingBoard?.width && drawingBoard?.height) {
      //     const centerX = drawingBoard?.width / 2;
      //     const centerY = drawingBoard?.height / 2;

      //     if (position === 'left') {
      //       element.set({
      //         textAlign: 'left',
      //         left: element.width / 2,
      //         top: centerY,
      //       });
      //     } else if (position === 'center') {
      //       element.set({
      //         textAlign: 'center',
      //         left: centerX,
      //         top: centerY,
      //       });
      //     } else {
      //       element.set({
      //         textAlign: 'right',
      //         left: drawingBoard?.width - element.width / 2,
      //         top: centerY,
      //       });
      //     }
      //   }
      // }

      drawingBoard?.renderAll();
      drawingBoard?.fire('object:modified', { target: element });
    }
  };

  useEffect(() => {
    if (toolbarRef.current) {
      const topCoords = getTopCoords(element);
      const toolbarHeight =
        (toolbarRef.current as HTMLElement)?.clientHeight || 0;
      const toolbarWidth =
        (toolbarRef.current as HTMLElement)?.clientWidth || 0;
      const toolbarX = topCoords.x - toolbarWidth / 2;
      let toolbarY = topCoords.y - toolbarHeight - 50;
      if (topCoords.y < toolbarHeight + 50) {
        toolbarY = topCoords.y + toolbarHeight + 50;
      }

      setCoords({ x: toolbarX, y: toolbarY });
    }
  }, [element, top]);

  const updateImage = (newSrc: any) => {
    element.setSrc(newSrc, () => {
      element.scaleToWidth(width);
      element.scaleToHeight(height);
      drawingBoard?.requestRenderAll();
      drawingBoard?.fire('object:modified', { target: element });
    });
  };

  const changeFontFamily = (fontFamily: string) => {
    element.set({
      fontFamily,
    });
    drawingBoard?.requestRenderAll();
    drawingBoard?.fire('object:modified', { target: element });
  };

  const changeFontSize = (fontSize: string) => {
    if (fontSize !== element.fontSize) {
      element.set({
        fontSize,
      });
      drawingBoard?.requestRenderAll();
      drawingBoard?.fire('object:modified', { target: element });
    }
  };

  const fitImageElement = (fitEntire: boolean) => {
    if (drawingBoard) {
      const clipPath = drawingBoard.clipPath;
      const targetWidth = drawingBoard.width ?? 0;
      const targetHeight = drawingBoard.height ?? 0;

      const scaleTo = (height: number, width: number) => {
        element.scaleToHeight(height);
        element.scaleToWidth(width);
        const top = clipPath
          ? (clipPath.top ?? 0) + (height - element.getScaledHeight()) / 2
          : (height - element.getScaledHeight()) / 2;

        const left = clipPath
          ? (clipPath.left ?? 0) + (width - element.getScaledWidth()) / 2
          : (width - element.getScaledWidth()) / 2;

        element
          .set({
            top: top + element.getScaledHeight() / 2,
            left: left + element.getScaledWidth() / 2,
          })
          .setCoords();
      };

      const centerScale = (height: number, width: number) => {
        const scaleX = Math.min(
          (width / element.width) * 1.5,
          (height / element.height) * 1.5,
        );
        element.scaleX = scaleX;
        element.scaleY = scaleX;
        const centerX = clipPath
          ? (clipPath.left ?? 0) + (width ?? 0) / 2
          : width / 2;
        const centerY = clipPath
          ? (clipPath.top ?? 0) + (height ?? 0) / 2
          : height / 2;

        const imageLeft = centerX;
        const imageTop = centerY;

        element
          .set({
            left: imageLeft,
            top: imageTop,
          })
          .setCoords();
      };

      if (clipPath) {
        if (!fitEntire) {
          scaleTo(clipPath.height ?? 0, clipPath.width ?? 0);
        } else {
          centerScale(clipPath.height ?? 0, clipPath.width ?? 0);
        }
      } else {
        if (!fitEntire) {
          scaleTo(targetHeight, targetWidth);
        } else {
          centerScale(targetHeight, targetWidth);
        }
      }

      drawingBoard.requestRenderAll();
      drawingBoard.fire('object:modified', { target: element });
    }
  };

  const TextTool = () => {
    const [selectedColor, setSelectedColor] = useState(element.fill);
    const inputRef = useRef<any>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const handleChange = debounce((newColor) => {
      setSelectedColor(newColor.hex);
      element.set({ fill: newColor.hex });
      drawingBoard?.requestRenderAll();
      drawingBoard?.fire('object:modified', { target: element });
    }, 400);

    return (
      <MainWrapper>
        <SelectList
          items={fontFamilies.map((font) => ({
            label: font.name,
            value: font.value,
          }))}
          onChange={(font) => changeFontFamily(font)}
          selectedValue={element.fontFamily}
          addFontFamilyToLabel={true}
        />
        <SelectList
          items={fontSizeOptions.map((font) => ({
            label: font.toString(),
            value: font.toString(),
          }))}
          onChange={(font) => changeFontSize(font)}
          selectedValue={element.fontSize}
          listType="input"
          width="45px"
          setSelectedFontSize={changeFontSize}
        />
        <FlexBox className="box icons" alignItems="center">
          <CustomToolTip placement="top" title="Align Left" onClick={() => alignText('left')}>
            <img
              src={AlignLeftIcon}
              alt="left-align"
            />
          </CustomToolTip>
          <CustomToolTip placement="top" title="Align Center" onClick={() => alignText('center')}>
            <img
              src={AlignCenterIcon}
              alt="center-align"
            />
          </CustomToolTip>
          <CustomToolTip placement="top" title="Align Right" onClick={() => alignText('right')}>
            <img
              src={AlignRightIcon}
              alt="right-align"
            />
          </CustomToolTip>
          <div
            style={{ position: 'relative' }}
            onClick={() => setShowColorsPopup(!showColorsPopup)}
          >
            <CustomToolTip placement="top" title="Font Color">
              <img
                className="text-color-btn"
                src={TextColorIcon}
                alt="text-color"
              />
            </CustomToolTip>
            {showColorsPopup && (
              <ClickAwayListener onClickAway={() => setShowColorsPopup(false)}>
                <div
                  onClick={(e) => e.stopPropagation()}
                  id="color-square"
                  className="color-box"
                >
                  <ColorPicker
                    color={selectedColor}
                    onChange={handleChange}
                    presets={colorsPallete.map(
                      (colorItem) => colorItem.colorCode,
                    )}
                    // onSwatchHover={handleSwatchHover}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
          <CustomToolTip placement="top" title="Duplicate">
            <img
              src={DuplicateIcon}
              onClick={() => duplicateObj(drawingBoard, files)}
              alt="duplicate"
            />
          </CustomToolTip>
          <CustomToolTip placement="top" title="Delete">
            <img
              src={DeleteIcon}
              onClick={(e: React.MouseEvent) =>
                deleteObj(e, drawingBoard, selectedElementId)
              }
              className="bigger-icon"
              alt="Delete"
            />
          </CustomToolTip>
          {/* <img src={MoreIcon} alt="More" className="bigger-icon" /> */}
        </FlexBox>
      </MainWrapper>
    );
  };

  const ImageTools = () => {
    return (
      <FlexBox className="box icons" alignItems="center">
        {isPersonalized && imageOptions.length > 0 && (
          <SelectList
            selectedValue={''}
            label="Select Image"
            items={imageOptions.map((image: any) => ({
              label: image.name,
              value: image.src,
            }))}
            onChange={(value) => updateImage(value)}
          />
        )}
        {((isPersonalized && imageOptions.length > 0) || !isPersonalized) && (
          <>
            <CustomToolTip placement="top" title="Flip Vertically">
              <img
                src={FlipVerticalIcon}
                onClick={() => flipVertically(drawingBoard)}
                alt="flip-vertical"
              />
            </CustomToolTip>
            <CustomToolTip placement="top" title="Flip Horizontally">
              <img
                src={FlipHorizontalIcon}
                onClick={() => flipHorizontally(drawingBoard)}
                alt="flip-horizontal"
              />
            </CustomToolTip>
          </>
        )}
        <CustomToolTip placement="top" title="Fit to Placeholder">
          <img
            src={FitScreenIcon}
            alt="fit-screen"
            onClick={() => fitImageElement(false)}
          />
        </CustomToolTip>
        <CustomToolTip placement="top" title="Fill to Placeholder">
          <img
            src={AspectRatioFitIcon}
            alt="aspect-ratio"
            onClick={() => fitImageElement(true)}
          />
        </CustomToolTip>
        {!isPersonalized && (
          <>
            <CustomToolTip placement="top" title="Duplicate">
              <img
                src={DuplicateIcon}
                onClick={() => duplicateObj(drawingBoard, files)}
                alt="duplicate"
              />
            </CustomToolTip>
            <CustomToolTip placement="top" title="Delete">
              <img
                src={DeleteIcon}
                onClick={(e: React.MouseEvent) =>
                  deleteObj(e, drawingBoard, selectedElementId)
                }
                className="bigger-icon"
                alt="Delete"
              />
            </CustomToolTip>
            {/* <img src={MoreIcon} alt="More" className="bigger-icon" /> */}
          </>
        )}
      </FlexBox>
    );
  };

  return (
    <Wrapper y={coords.y} x={coords.x} className="box" ref={toolbarRef}>
      {type === 'custom-image' ? <ImageTools /> : <TextTool />}
    </Wrapper>
  );
};

export default ToolBar;

const Wrapper = styled.div<any>`
  z-index: 1102;
  padding: 8px;
  display: flex;
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  height: 38px;
  width: auto;
  padding: 6px;
  display: ${(props) =>
      props.y === undefined || props.x === undefined ? 'none' : 'block'}
    .color-div {
    width: 245px;
  }

  .icons {
    > * {
      height: 16px;
      width: 16px;
      padding: 11px;
      :not(:last-child) {
        border-right: 1px solid ${colors.grey[300]};
      }
      &.bigger-icon {
        width: 24px;
        height: 24px;
        padding: 8px;
      }
      &:hover {
        background-color: #e6e6e6;
        &:first-child {
          border-radius: 6px 0px 0px 6px;
        }
        &:last-child {
          border-radius: 0px 6px 6px 0px;
        }
      }
    }
  }
  #custom-tooltip {
    position: absolute;
    z-index: 2;
    width: max-content;
    display: none;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  .color-box {
    position: relative;
    width: fit-content;
    top: 34px;
    right: 90px;
  }
  .text-color-btn {
    padding: 11px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
