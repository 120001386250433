import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQuery';
import {
  CancelOrderPayload,
  GetOrdersQueryParams,
  GetOrdersResponse,
  GetOrderDetailsResponse,
  OrderIdQueryParam,
  UpdateOrderPayload,
  UpdateProductPersonalizationPayload,
} from '../../interface/orders-interface';
import { appendQueryParams } from '../../helper/appenedQueryParams';
import { CreateSampleOrderPayload } from '../../interface/sample-order-interface-v2';
interface GetShippingCostResponse {
  success: boolean;
  shippingCost: number;
}

export const ordersAPI = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'orders',
  tagTypes: ['getOrderById', 'getOrders'],
  endpoints: (builder) => ({
    placeOrder: builder.mutation<
      GetOrderDetailsResponse,
      { orderId: string | undefined; paymentMethodId?: string | undefined }
    >({
      query: (params) => ({
        url: `/orders/place-order/${params.orderId}`,
        method: 'POST',
        body: { paymentMethodId: params.paymentMethodId },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['getOrders']),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          dispatch(
            ordersAPI.util.updateQueryData('getOrderById', params, (draft) => {
              Object.assign(draft, updatedData);
            }),
          );
        } catch (error) {}
      },
    }),
    getAllOrders: builder.query<GetOrdersResponse, GetOrdersQueryParams>({
      query: (params) => {
        var url = `/orders?storeId=${params.storeId}`;
        return appendQueryParams(url, params);
      },
      providesTags: ['getOrders'],
    }),
    getOrderById: builder.query<GetOrderDetailsResponse, OrderIdQueryParam>({
      query: (params) => `/orders/${params.orderId}`,
      providesTags: ['getOrderById'],
    }),
    getPersonalizationDataByOrderId: builder.query<any, OrderIdQueryParam>({
      query: (params) => `/orders/personalize-orders/${params.orderId}`,
    }),
    updateProductPersonalizationForOrder: builder.mutation<
      { message: string },
      UpdateProductPersonalizationPayload
    >({
      query: (params) => ({
        url: `/orders/personalize-order`,
        method: 'POST',
        body: params,
      }),
    }),
    sendPersonalizedOrderToFulfillment: builder.mutation<
      string,
      OrderIdQueryParam & { paymentMethodId?: string }
    >({
      query: (params) => ({
        url: `/orders/fulfill-personalize-order`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['getOrderById']),
    }),
    updateOrder: builder.mutation<GetOrderDetailsResponse, UpdateOrderPayload>({
      query: (params) => ({
        url: `/orders/${params.orderId}`,
        method: 'PATCH',
        body: params.body.customer
          ? { customer: params.body.customer }
          : { shippingAddress: params.body.shippingAddress },
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedOrder } = await queryFulfilled;
          dispatch(
            ordersAPI.util.updateQueryData(
              'getOrderById',
              { orderId: params.orderId },
              (draft: any) => {
                Object.assign(draft, updatedOrder);
              },
            ),
          );
        } catch (error) {}
      },
    }),
    cancelOrder: builder.mutation<GetOrderDetailsResponse, CancelOrderPayload>({
      query: (params) => ({
        url: `orders/${params.orderId}?reason=${params.reason}`,
        method: 'DELETE',
      }),
      async onQueryStarted(params, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: updatedOrder } = await queryFulfilled;
          dispatch(
            ordersAPI.util.updateQueryData(
              'getOrderById',
              { orderId: params.orderId },
              (draft: any) => {
                Object.assign(draft, updatedOrder);
              },
            ),
          );
        } catch (error) {}
      },
    }),

    updatePaymentMethod: builder.mutation<
      void,
      { retry?: boolean; paymentMethodId?: string; isSampleOrder?: boolean }
    >({
      query: ({ paymentMethodId, retry }) => ({
        url: `/orders/process-on-hold-orders`,
        method: 'POST',
        body: {
          retry,
          paymentMethodId,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['getOrders']),
    }),

    getShippingCost: builder.mutation<
      GetShippingCostResponse,
      {
        products: {
          id: string;
          quantity: number;
          printProviderId: string;
          variantId: string;
        }[];
      }
    >({
      query: (body) => ({
        url: '/orders/get-shipping-cost',
        method: 'POST',
        body,
      }),
    }),
    createSampleOrder: builder.mutation<
      { message: string },
      CreateSampleOrderPayload
    >({
      query: (params) => ({
        url: '/orders/create-sample-order',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['getOrders']),
    }),
  }),
});

export const {
  usePlaceOrderMutation,
  useGetAllOrdersQuery,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
  useCancelOrderMutation,
  useLazyGetPersonalizationDataByOrderIdQuery,
  useUpdateProductPersonalizationForOrderMutation,
  useUpdatePaymentMethodMutation,
  useSendPersonalizedOrderToFulfillmentMutation,
  useGetShippingCostMutation,
  useCreateSampleOrderMutation,
} = ordersAPI;
